<template>
  <div>
    <CyDetails
      :item-id="member.id"
      :on-save="save"
      :on-cancel="cancel"
      :on-delete="$toggle.showDeleteDialog"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="loading"
      :saving="saving"
      :deleting="deleting"
      :delete-btn-text="$t('forms.btnRemove')">
      <template slot="details_form">
        <CyAlert
          theme="error"
          :content="errors"/>
        <v-select
          v-model="$v.formContent.roleCanonical.$model"
          :disabled="!canUpdate"
          :error-messages="roleErrors"
          :items="available.roles"
          :label="$t('forms.role')"
          hide-selected
          item-value="canonical"
          item-text="name"
          required
          class="required-field"/>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmRemoveHeader')"
      :action-btn-func="unassignOrgMember"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      :action-btn-text="$t('forms.btnRemove')"
      modal-type="delete"
      small>
      <div class="pb-5">
        <p> {{ $t('confirmRemoveSentence') }} </p>
        <h3>{{ displayName(member) }}</h3>
      </div>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CyDetails from '@/components/details.vue'
import { constructBreadcrumb, displayName } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageMember',
  components: {
    CyDetails,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.displayName(this.member), [
      {
        label: this.$t('routes.members'),
        name: 'members',
      },
    ])
  },
  header () {
    if (this.loading) return {}
    return {
      avatar: {
        type: 'avatar',
        item: this.member,
      },
      title: `${this.member.given_name} ${this.member.family_name}`,
      metas: [
        this.member.email,
        this.$t('joined', [this.$date.format(this.$date.$getTimestamp(this.member.created_at), 'MMM d, yyyy')]),
      ],
      updatedAt: this.member.updated_at,
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  validations: {
    formContent: {
      roleCanonical: { required },
    },
  },
  data: () => ({
    formContent: {
      roleCanonical: '',
    },
    showDeleteDialog: false,
    deleting: false,
    saving: false,
    loading: true,
  }),
  computed: {
    ...mapState('organization', {
      available: (state) => state.available,
    }),
    ...mapState('organization/member', {
      errors: (state) => state.errors,
    }),
    ...mapGetters('organization/member', [
      'member',
    ]),
    roleErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.roleCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canCancel () {
      return this.$hasDataChanged('formContent')
    },
    canSave () {
      return this.canCancel && !this.$v.formContent.$invalid
    },
    canUpdate () {
      return this.$cycloid.permissions.canDisplay('UpdateOrgMember', this.member.canonical)
    },
  },
  async mounted () {
    await Promise.all([
      this.FETCH_AVAILABLE({ keyPath: 'roles' }),
      this.getMember(),
    ])
    this.loading = false
  },
  methods: {
    displayName,
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/member', [
      'GET_ORG_MEMBER',
      'UPDATE_ORG_MEMBER',
      'REMOVE_ORG_MEMBER',
    ]),
    async getMember () {
      await this.GET_ORG_MEMBER(this.id)
      if (_.isEmpty(this.member)) return
      this.formContent.roleCanonical = this.member.role.canonical
      this.$setOriginalData()
    },
    cancel () {
      this.$resetData('formContent')
      this.$v.$reset()
    },
    async save () {
      this.saving = true
      const { member, formContent: { roleCanonical }, $router } = this
      await this.UPDATE_ORG_MEMBER({ member: { ...member, role_canonical: roleCanonical }, $router })
      this.saving = false
    },
    async unassignOrgMember () {
      const { id, $router } = this
      this.deleting = true
      await this.REMOVE_ORG_MEMBER({ id, $router })
      this.deleting = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.member',
        confirmRemoveHeader: 'Remove member',
        confirmRemoveSentence: 'Are you sure that you want to remove this member?',
        joined: 'Joined on {0}',
      },
      es: {
        title: '@:routes.member',
        confirmRemoveHeader: 'Eliminar miembro',
        confirmRemoveSentence: '¿Estás seguro de querer eliminar este miembro?',
        joined: 'Se unio a {0}',
      },
      fr: {
        title: '@:routes.member',
        confirmRemoveHeader: 'Retirer ce membre',
        confirmRemoveSentence: 'Êtes-vous sûr de vouloir retirer ce membre ?',
        joined: 'Rejoint le {0}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .member-card {
    display: flex;
    flex-direction: row;
    margin: 40px 0 60px;

    &__avatar {
      align-items: center;
      margin-right: 30px;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
    }
  }
</style>
